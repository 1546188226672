<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Cargar Imagen</span>
      </div>
      <div class="text item">
      <ValidationObserver ref="form" v-slot="{ }">
          <el-form label-position="top" label-width="100px" :model="form">
              <el-image :src="$urlGlobalApi + form.path">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <!--<el-form-item label="Titulo">
                <ValidationProvider name="Titulo" rules="required|max:60" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span><br>
                </ValidationProvider>
              </el-form-item>-->
              <el-form-item label="Cargar Imagen">
            <el-upload
                drag
                name="image"
                action="#"
                :http-request="postImage"
                :on-success="handleAvatarSuccess"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>
              </el-upload>
            </el-form-item>
             <el-form-item label="URL Imagen">
                <!--<ValidationProvider name="Ruta" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.path"></el-input>
                  <span>{{ errors[0] }}</span><br>
                </ValidationProvider>-->
                 <div v-if="form.path">
                       {{$urlGlobalApi + form.path}}
                    </div>
                    <div v-else>
                    </div>
              </el-form-item>
              <!--<el-form-item label="Descripción">
                <ValidationProvider name="Description" rules="required|max:500" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.description"></el-input>
                  <span>{{ errors[0] }}</span><br>
                  <span>Para aludir al usuario escriba "[+user+]" </span>
                </ValidationProvider>
              </el-form-item>-->
              <!--<el-button
                @click.stop.prevent="postImage"
                type="primary"
                icon="el-icon-upload" :disabled="invalid">
                Guardar</el-button>
              </el-form-item>-->
          </el-form>
        </ValidationObserver>
        </div>
    </el-card>
  </el-col>
</div>
</template>

<script>
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      form: {
        path: ''
      },
      loading: false
    }
  },
  methods: {
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('width', '300')
      formData.append('height', '300')
      formData.append('title', 'Imagen')
      formData.append('folder', 'images')
      formData.append('alt', 'Avigrupo-image')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data)
        this.$message({
          showClose: true,
          message: 'Se ha guardado la Imagen!',
          type: 'success'
        })
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccess (res, file) {
      this.form.path = res.path
      // console.log(this.form.header)
    }
    // async onStore () {
    //  await uploadImageFile(this.form)
    //  .then(() => {
    //      // let res = response.data
    //      this.$message({
    //        showClose: true,
    //        message: 'Se ha creado una nueva Noticia!',
    //        type: 'success'
    //      })
    //      this.$router.push({ name: 'news.create' })
    //    })
    //    .catch(this.responseCatch)
    // }
  }
}
</script>
